<template>
  <v-container
    v-if="$store.state.userData.is_admin == true"
    fluid style="max-width: 1100px; width: 90%;"
  >
    <v-container flex>
      <div class="text-left mb-5">
        <h1 class="mt-5">Subscription</h1>    
      </div>
      <div v-if="planInfo != null">
        <v-simple-table class="text-center pricing-table">
          <template v-slot:default>
            <tbody>  
              <tr>
                <td>Your current plan</td>
                <td>{{ planInfo.name }}</td>
              </tr>
              <tr v-if="validUntil != null && planInfo.id != 1">
                <td>Active until</td>
                <td> {{ validUntil | moment('MMMM Do, YYYY')}}</td>
              </tr>
              <tr v-if="subscriptionInfo != null">
                <td>Next payment</td>
                <td>{{ subscriptionInfo.next_payment.amount }} {{ subscriptionInfo.next_payment.currency }} on {{ subscriptionInfo.next_payment.date | moment('MMMM Do, YYYY') }}</td>
              </tr>
              <tr v-if="subscriptionInfo != null">
                <td></td>
                <td class="py-2">
                  <v-btn small color="primary" :href="subscriptionInfo.update_url" target="_blank" class="mr-2">Update Payment method</v-btn>
                  <v-btn small color="primary" :href="subscriptionInfo.cancel_url" target="_blank" class="">Cancel Subscription</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <hr v-if="planInfo.id == 1"/>
        <div v-if="subscriptionInfo != null" class="mt-3">
            If you wish to change your pricing plan, please cancel your current plan and then upgrade again.<br/>
            <a href="javascript:void(0)" @click="showPricingPlans = true">Show pricing plans</a>
        </div>

      </div>
      <div v-if='plans != null && (subscriptionInfo == null || showPricingPlans === true)' class="" style="margin-top: 5rem;">
        <v-tabs class="mb-5" centered>
          <v-tab class="tabs" @click="billingPeriod='yearly'">YEARLY (2 months free <v-icon style="font-size: 1rem;">mdi-sparkles</v-icon>)</v-tab>
          <v-tab class="tabs" @click="billingPeriod='monthly'">MONTHLY</v-tab>
        </v-tabs>
        <v-simple-table class="text-center pricing-table mt-5">
          <template v-slot:default>
            <tbody>  
              <tr>
                <th></th>
                <th class="text-center">{{ plans['free'].name }}</th>
                <th class="text-center">
                  <div>{{ plans['small'].name }}</div>
                  <v-btn
                    v-if="subscriptionInfo == null"
                    class="upgrade-button"
                    @click="upgrade('small')"
                  >
                    Upgrade
                  </v-btn>
                </th>
                <th class="text-center">
                  <div>{{ plans['big'].name }}</div>
                  <v-btn
                    v-if="subscriptionInfo == null"
                    class="upgrade-button"
                    @click="upgrade('big')"
                  >
                    Upgrade
                  </v-btn>
                </th>
              </tr>
              <tr>
                <td v-if="billingPeriod=='monthly'" class="text-left py-2">
                  Price per month, for entire Slack workspace.<br/>
                  <span style="font-size:0.8rem;">Billed every 30 days. Cancel anytime.</span>
                </td>
                <td v-if="billingPeriod=='yearly'" class="text-left py-2">
                  Price per year, for entire Slack workspace.<br/>
                  <span style="font-size:0.8rem;">Billed yearly. Cancel anytime.</span>
                </td>
                <td class="price">${{ plans['free'].price | numeral('0.00') }}<span class="mo">/{{billingPeriod == 'monthly' ? 'mo' : 'year'}}</span></td>
                <td class="price">
                  ${{ plans['small'].price | numeral('0.00') }}<span class="mo">/{{billingPeriod == 'monthly' ? 'mo' : 'year'}}</span><br/>
                </td>
                <td class="price">${{ plans['big'].price | numeral('0.00') }}<span class="mo">/{{billingPeriod == 'monthly' ? 'mo' : 'year'}}</span></td>
              </tr>
              <tr>
                <td class="text-left">Monthly vote limit</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].vote_count_limit >= 9999999">∞ Unlimited</span>
                  <span v-else>{{plans[plan].vote_count_limit}}</span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Max. choices per Quick Poll</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].quick_poll_options_limit >= 9999999">∞ Unlimited</span>
                  <span v-else>{{plans[plan].quick_poll_options_limit}}</span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Max. choices per Open Poll</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].open_poll_options_limit >= 9999999">∞ Unlimited</span>
                  <span v-else>{{plans[plan].open_poll_options_limit}}</span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Max. recurring polls</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].recurring_polls >= 9999999">∞ Unlimited</span>
                  <span v-else>{{plans[plan].recurring_polls}}</span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Export to XLSX</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].export_to_csv"><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></span>
                  <span v-else><v-icon>mdi-minus</v-icon></span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Unlimited number of polls</td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
              </tr>
              <tr>
                <td class="text-left">Custom upvote emojis</td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
              </tr>
              <tr>
                <td class="text-left">Web view</td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
                <td><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></td>
              </tr>
              <tr>
                <td class="text-left">Anonymous polls</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].quick_poll_anonymous"><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></span>
                  <span v-else><v-icon>mdi-minus</v-icon></span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Single vote per person setting</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].quick_poll_vote_limiting"><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></span>
                  <span v-else><v-icon>mdi-minus</v-icon></span>
                </td>
              </tr>
              <tr>
                <td class="text-left">Poll scheduling</td>
                <td v-for="(plan, plan_ind) in ['free', 'small', 'big']"  :key="plan_ind">
                  <span v-if="plans[plan].scheduled_polls"><v-icon class="checkbox-icon-true">mdi-check-bold</v-icon></span>
                  <span v-else><v-icon>mdi-minus</v-icon></span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-5 mb-10 text-center">
          <br/>
          <br/>
          <p>
            VAT may be applied depending on your local VAT laws. If you have a VAT number you can add it during checkout, which should remove VAT from your total price.
          </p>
          <p>
            Need help with pricing plans and upgrade? Please send an email to <a href="mailto:help@upvote.chat">help@upvote.chat</a>
          </p>
          <p>
            We use <a href="https://paddle.com">Paddle.com</a> for secure payment processing.
          </p>
          <br/>
        </div>
      </div>
    </v-container>
    <PaymentDetailsDialog
      :showDialogToggle="paymentDetailsDialogVisible"
      :paddleProductId="paddleProductId"
      :planInfo="planToPurchase"
      :billingPeriod="billingPeriod"
      @paymentDone="onPaymentDone"
    ></PaymentDetailsDialog>
    <v-snackbar
      app
      bottom right
      timeout="1200"
      color="info"
      v-model="showSnackbar"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import { SUBSCRIPTION_TYPES } from '../Constants'
import PaymentDetailsDialog from '../components/Payment/PaymentDetailsDialog.vue'
import ReviewAndPayDialog from '../components/Payment/ReviewAndPayDialog.vue'
import SubscriptionUsersDialog from '../components/Payment/SubscriptionUsersDialog.vue'

export default {
  name: 'AdminPlans',
  components: {
    PaymentDetailsDialog,
  },
  props: {
  },
  data () {
    return {
      planInfo: null,
      validUntil: null,
      subscriptionInfo: null,
      showPricingPlans: false,

      allPlans: null,
      plans: null,
      billingPeriod: 'yearly',

      planToPurchase: null,
      paddleProductId: null,
      paymentDetailsDialogVisible: false,
      paymentDone: false,

      reviewAndPayDialogVisible: false,
      fetchSubscriptionRetry: null,
      fetchSubscriptionRetryInProgress: false,

      showSnackbar: false,
      snackbarText: ''
    }
  },
  computed: {
  },
  watch: {
    billingPeriod() {
      this.updatePlans()
    }
  },
  methods: {
    fetchCurrentPlanInfo () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-current-plan-info',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.planInfo = response.data.plan
        this.validUntil = response.data.valid_until
        this.subscriptionInfo = response.data.subscription_info
      }).catch((error) => {
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    fetchPricingPlans () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-pricing-plans',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.allPlans = response.data
        this.updatePlans()
      }).catch((error) => {
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    upgrade (plan) {
      this.paddleProductId = this.plans[plan].paddle_id
      this.planToPurchase = this.plans[plan]
      this.paymentDetailsDialogVisible = !this.paymentDetailsDialogVisible
    },
    onPaymentDone() {
      this.paymentDetailsDialogVisible = !this.paymentDetailsDialogVisible
      setTimeout(()=>{
        this.fetchCurrentPlanInfo()
      }, 1000)
    },
    updatePlans() {
      if (this.billingPeriod == 'monthly') {
        this.plans = {
          'free': this.allPlans['free'],
          'small': this.allPlans['small'],
          'big': this.allPlans['big']
        }
      } else {
        this.plans = {
          'free': this.allPlans['free'],
          'small': this.allPlans['small_yearly'],
          'big': this.allPlans['big_yearly']
        }
      }
    }
  },
  mounted: function () {
    this.fetchCurrentPlanInfo()
    this.fetchPricingPlans()
  },
}
</script>

<style scoped>
  .card-text {
    color: black!important;
  }

  .checkbox-icon-true {
    color: #59c359!important;
  }
  .tabs {
    text-transform: none;
  }

  .pricing-table td {
    font-size: 1rem!important;
    font-weight: 400;
  }

	.pricing-table th {
		font-size: 1.8rem!important;
		vertical-align: top;
		padding: 10px 0!important;
	}

	.pricing-table .price {
		font-size: 1.4rem!important;
		font-weight: bold;
	}

  .mo {
		font-size: 1.0rem;
		font-weight: 400;
	}

	.upgrade-button {
    font-size: 1rem!important;
    letter-spacing: normal;
    color: #ffffff!important;
		background-color: rgb(253, 58, 92)!important;
		border: none!important;
		width: 120px!important;
		max-width: 98%!important;
	}
</style>
